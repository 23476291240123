import React from "react";
import {IoLogoWhatsapp,IoLogoFacebook,IoLogoInstagram,IoIosClose} from "react-icons/io";
import "./styles.css";
import { motion } from "framer-motion";
const PopUp =()=>{
  
  function hiddePopUp(){
    const popUp = document.getElementById("popUp"); 
    popUp?.setAttribute("style", "top:-1000px")
  }
    return(
        <div id="popUp">
                  <motion.div  style={{"background":"none","border":"none","width":"60px","height":"60px" }}
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}>
        <IoIosClose  onClick={()=>hiddePopUp()} style={{"color":"#D1DFEB","fontSize":50}}/>
        </motion.div>
        <div className="popUpContent">

        <h5>Gostou?Estamos esperando por você,<br/>Entre em contato:</h5>
        <div className="buttonGroup">
        <div className="buttonDirect"><a target="blank" rel="noopener noreferrer" href="https://wa.me/5547991511226"><span>Mensagem Direta </span><IoLogoWhatsapp style={{"color":"#35A632","fontSize":30}}/></a></div>
        <p>ou</p>
        <div className="buttonDirect"><a target="blank" rel="noopener noreferrer" href="https://www.instagram.com/costafloridaparadise"><span>Mensagem Direta</span><IoLogoInstagram style={{"color":"#2D3A45","fontSize":35,}}/></a></div>
        </div>
        <p id="singlePopP">
          Não perca nada, nos acompanhe<br/> em nossas redes sociais
        </p>
        <div className="iconGroup">
          
  <a target="blank" rel="noopener noreferrer" href="https://www.instagram.com/costafloridaparadise"><IoLogoInstagram style={{"color":"#C13584","fontSize":50,}}/>
  </a>
  <a target="blank" rel="noopener noreferrer" href="https://www.facebook.com/Pousada-Costa-Florida-349947545858712/"
  > <IoLogoFacebook style={{"color":"#4267B2","fontSize":50}}/></a>
  </div>
  </div>
      </div>
      )
}
export default PopUp;