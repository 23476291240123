import React from "react";
import "./styles.css";
import timeLapse from "../../assets/TimelapseFull.MP4"
const TimeLapse =()=>{
    return(
    <video autoPlay loop muted 

    src={timeLapse}
    alt="TimeLapse do Nascer do sol na pousada"
    
    className='TimeLapse'>
    </video>)
}
export default TimeLapse;