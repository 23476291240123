import React from 'react';
import './App.css';
import Routes from './router';
function App() {
  return (
    	
    <Routes/>
  );
}

export default App;
