import React, {useState,useEffect} from "react";
import "./style.css";
import {Link} from "react-router-dom";
import { IoIosArrowForward,IoIosArrowBack} from "react-icons/io";
import { motion } from "framer-motion";
import Footer from "../../components/Footer";


import logoDefault from "../../assets/LogoDefaultSize.png";
import TimeLapse from "../../components/TimeLapse/index";

import PraiaExclusiva from "../../assets/PraiaExclusiva.jpg";
import CafeDaManha from "../../assets/CafeDaManha.jpg";
import Restaurante from "../../assets/Restaurante.jpg"
import jantarTest from "../../assets/jantarTest.jpg";
import macaco from "../../assets/macaco.jpg";
import Externo from "../../assets/Externo.jpg";
import vazio from "../../assets/void.jpg";

import SuiteN01 from "../../assets/SuiteN01.jpg";
import SuiteN02 from "../../assets/SuiteN02.jpg";
import SuiteN03 from "../../assets/SuiteN03.jpg";

import SuiteC01 from "../../assets/SuiteC01.jpg";
import SuiteC02 from "../../assets/SuiteC02.jpg";
import SuiteC03 from "../../assets/SuiteC03.jpg";
import SuiteC04 from "../../assets/SuiteC04.jpg";
import SuiteC05 from "../../assets/SuiteC05.jpg";

import SuiteS01 from "../../assets/SuiteS01.jpg";
import SuiteS02 from "../../assets/SuiteS02.jpg";
import SuiteS03 from "../../assets/SuiteS03.jpg";
import SuiteS04 from "../../assets/SuiteS04.jpg";

import HospedeCosta from "../../assets/Galery/HospedeCosta.jpeg";
import HospedeCosta2 from "../../assets/Galery/HospedeCosta2.jpeg";
import HospedeCosta3 from "../../assets/Galery/HospedeCosta3.jpeg";
import HospedeCosta4 from "../../assets/Galery/HospedeCosta4.jpeg";
import HospedeCosta5 from "../../assets/Galery/HospedeCosta5.jpeg";
import HospedeCosta6 from "../../assets/Galery/HospedeCosta6.jpeg";
import HospedeCosta7 from "../../assets/Galery/HospedeCosta7.jpeg";
import HospedeCosta8 from "../../assets/Galery/HospedeCosta8.jpeg";
import HospedeCosta9 from "../../assets/Galery/HospedeCosta9.jpeg";
import HospedeCosta10 from "../../assets/Galery/HospedeCosta10.jpeg";
import HospedeCosta11 from "../../assets/Galery/HospedeCosta11.jpeg";

import Balanco from "../../assets/Galery/balanco.jpeg";
import Balanco2 from "../../assets/Galery/balanco2.jpeg";
import Balanco3 from "../../assets/Galery/balanco3.jpeg";
import Balanco4 from "../../assets/Galery/balanco4.jpeg";
import Balanco5 from "../../assets/Galery/balanco5.jpeg";

import Barco from "../../assets/Galery/barco.jpeg";
import Barco2 from "../../assets/Galery/barco2.jpeg";
import Barco3 from "../../assets/Galery/barco2.jpeg";

import Praia from "../../assets/Galery/praia.jpg";
import Prai2 from "../../assets/Galery/praia2.jpeg";
import Praia3 from "../../assets/Galery/praia3.jpg";
import Praia4 from "../../assets/Galery/praia4.jpeg";
import Praia6 from "../../assets/Galery/praia6.jpeg";
import Praia7 from "../../assets/Galery/praia7.jpeg";

import SuiteRandom from "../../assets/Galery/Suitegalery.jpeg";
import SuiteRandom2 from "../../assets/Galery/Suitegalery2.jpg";
import SuiteRandom3 from "../../assets/Galery/Suitegalery3.jpeg";

import Random from "../../assets/Galery/GRID1.jpeg";
import Random2 from "../../assets/Galery/Grid2_1.jpeg";
import Random3 from "../../assets/Galery/Grid2_2.jpeg";
import Random4 from "../../assets/Galery/Grid4-1_1.jpeg";
import Random5 from "../../assets/Galery/Grid4-1_2.jpeg";
import Random6 from "../../assets/Galery/Grid4-1_3.jpeg";
import Random7 from "../../assets/Galery/Grid4-1_4.jpeg";
import Random8 from "../../assets/Galery/Grid4-2_1.jpeg";
import Random9 from "../../assets/Galery/Grid4-2_2.jpeg";
import Random10 from "../../assets/Galery/Grid4-2_3.jpeg";
import Random11 from "../../assets/Galery/Grid4-2_4.jpeg";





const Home = () => {

        const [selectedId, setSelectId] = useState < number > (0);

        const aboutData = [{

                id: 1,
                title: "PRAIA EXCLUSIVA PARA HOSPEDES",
                description: "A Pousada possui uma praia de acesso exclusivo para os hospedes,com espriguiçadeiras, guarda-sol, Prancha de stand up,Balanço e redes na agua além de toda a beleza da lagoa da conceição.",
                img: PraiaExclusiva,
                imgNew: CafeDaManha,
                imgLast: vazio,



            },
            {
                id: 2,
                title: "CAFÉ DA MANHA",
                description: " Um café da manha reforçado preparado com amor e carinho e com tudo fresquinho para que você tenha disposição para aproveitar todas as nossa belezas.",
                img: CafeDaManha,
                imgNew: Restaurante,
                imgLast: PraiaExclusiva,




            },
            {
                id: 3,
                title: "ALMOÇO",
                description: "A pousada nao provém almoço mas temos 15 restaurantes proximos que oferecem um delicioso almoço.",
                img: Restaurante,
                imgNew: jantarTest,
                imgLast: CafeDaManha,


            },
            {
                id: 4,
                title: "JANTAR",
                description: " Oferecemos a oportunidade de um jantar romantico à luz de velas na sacada em frente a suite com vista para a lagoa.",
                img: jantarTest,
                imgNew: macaco,
                imgLast: Restaurante,



            },
            {
                id: 5,
                title: "SUAS BELEZAS",
                description: "Em um lindo dia de sol a beleza de nosso paraíso é realçada e você pode passear pelas trilhas onde temos casarões antigos, engenho e a famosa cachoeira da Costa da Lagoa.",
                img: macaco,
                imgNew: Externo,
                imgLast: jantarTest,

            },
            {
                id: 6,
                title: "ÁREA EXTERNA COM CHURRASQUEIRA",
                description: " A area externa possui um deck com churrasqueira a 8 metros da praia que pode ser utilizado pelos hospedes gratuitamente com reserva antecipada.",
                img: Externo,
                imgNew: vazio,
                imgLast: macaco,


            }
        ]

        const rightImg = document.getElementById("groupAboutNavR");
        const note = document.getElementById("note");
        const leftImg = document.getElementById("groupAboutNavL");
        const mainImg = document.getElementById("aboutUsFirst");
        const fixHoverN = document.getElementById("SuiteN");
        const fixHoverC = document.getElementById("SuiteC");
        const fixHoverS = document.getElementById("SuiteS");
       
        const SuiteSImg = [SuiteS01,SuiteS02, SuiteS03,SuiteS04];
        const SuiteCImg = [SuiteC01,SuiteC02,SuiteC03,SuiteC04,SuiteC05];
        const SuiteNImg = [SuiteN01,SuiteN02,SuiteN03];

        const SuiteN = {title: "NORTE",description: "suite localizada ao norte"};
        const SuiteC = {title: "CENTRAL",description: "suite localizada centralmente"};
        const SuiteS = {title: "SUL", description: "suite localizada ao sul"};
        const [suiteSelected, setSuiteSelected] = useState(SuiteC);
        const [suiteImgs, setSuiteImgs] = useState<string[]>(SuiteCImg);
        const [suiteSelectedCounter, setSuiteSelectedCounter] =useState<number>(2);
        const [suiteImgCount, setSuiteImgCount] =useState<number>(1);

       


        const scrollImg = document.getElementById("pics");
        // const wrapper =  document.getElementById("wrapper")
        // const wrapper21 = document.getElementById("wrapper21");
        // const wrapper22 = document.getElementById("wrapper22");
        // const wrapper41 = document.getElementById("wrapper41");
        // const wrapper42 = document.getElementById("wrapper42");
        // const wrapper43 = document.getElementById("wrapper43");
        // const wrapper44 = document.getElementById("wrapper44");
        // const wrapper45 = document.getElementById("wrapper45");
        // const wrapper46 = document.getElementById("wrapper46");
        // const wrapper47 = document.getElementById("wrapper47");
        // const wrapper48 = document.getElementById("wrapper48");
        // const [wrapper1Count, setWrapper1Count] =useState<number>(0);




        function handlePopUpDown(){
            const popUp = document.getElementById("popUp");
                popUp?.classList.add("bounce-in-top");
                popUp?.addEventListener("animationend",()=>{
                    popUp?.classList.remove("bounce-in-top")
                })
                popUp?.setAttribute("style", "top:32px");
        }






        function scrollUp(){
            if (suiteImgCount<suiteImgs.length) {
                setSuiteImgCount(suiteImgCount + 1)


            }
            const scrollImg = document.getElementById("pics");
            scrollImg?.scrollBy({
                top: 0,
                left: 750,
                behavior: 'smooth'
            });
        }

        function scrollDown(){
            if(suiteImgCount>1){
                setSuiteImgCount(suiteImgCount - 1)
            };
            const scrollImg = document.getElementById("pics");
            scrollImg?.scrollBy({
                top: 0,
                left: -250,
                behavior: 'smooth'
            });
           
        }

        function rightClick(){
            if(selectedId<5){
                setSelectId(selectedId + 1);
            }
        };

        function leftClick(){
            if(selectedId>0){
                setSelectId(selectedId - 1)
            }
        };

        function SuiteNAction(){
            const fixHoverN = document.getElementById("SuiteN");

            fixHoverC?.setAttribute("style", "background-color: #2D3A45;color: #D1DFEB;transition:all linear 0.2s");


            fixHoverS?.setAttribute("style", "background-color: #2D3A45;color: #D1DFEB;transition:all linear 0.2s");


            fixHoverN?.setAttribute("style", "background-color: #D1DFEB; color:#2D3A45;transition:all linear 0.2s");



            const scrollImg = document.getElementById("pics");
            scrollImg?.animate(
                [{
                        opacity: "1"
                    },
                    {
                        opacity: "0"
                    }
                ], {
                    duration: 1000
                });

            setSuiteSelectedCounter(1);
            setSuiteImgs(SuiteNImg);
            setSuiteSelected(SuiteN);
            scrollImg?.animate(
                [{
                        opacity: "0.5"
                    },
                    {
                        opacity: "1"
                    }
                ], {
                    duration: 1000
                });
                scrollImg?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setSuiteImgCount(1);
        };

        function SuiteCAction() {
            const fixHoverC = document.getElementById("SuiteC");
            fixHoverS?.setAttribute("style", "background-color: #2D3A45;color: #D1DFEB;transition:all linear 0.2s");

            fixHoverN?.setAttribute("style", "background-color: #2D3A45;color: #D1DFEB;transition:all linear 0.2s");

            fixHoverC?.setAttribute("style", "background-color: #D1DFEB; color:#2D3A45;transition:all linear 0.2s");



            const scrollImg = document.getElementById("pics");
            scrollImg?.animate(
                [{
                        opacity: "1"
                    },
                    {
                        opacity: "0"
                    }
                ], {
                    duration: 1000
                });
            setSuiteSelectedCounter(2);
            setSuiteImgs(SuiteCImg);
            setSuiteSelected(SuiteC);
            scrollImg?.animate(
                [{
                        opacity: "0.5"
                    },
                    {
                        opacity: "1"
                    }
                ], {
                    duration: 1000
                });
                scrollImg?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setSuiteImgCount(1);
        };

        function SuiteSAction() {
            const fixHoverS = document.getElementById("SuiteS");
            fixHoverN?.setAttribute("style", "background-color: #2D3A45;color: #D1DFEB;transition:all linear 0.2s");

            fixHoverC?.setAttribute("style", "background-color: #2D3A45;color: #D1DFEB;transition:all linear 0.2s");


            fixHoverS?.setAttribute("style", "background-color: #D1DFEB; color:#2D3A45;transition:all linear 0.2s");



            
            scrollImg?.animate(
                [{
                        opacity: "1"
                    },
                    {
                        opacity: "0"
                    }
                ], {
                    duration: 1000
                });
            setSuiteSelectedCounter(3);
            setSuiteImgs(SuiteSImg);
            setSuiteSelected(SuiteS);
            scrollImg?.animate(
                [{
                        opacity: "0.5"
                    },
                    {
                        opacity: "1"
                    }
                ], {
                    duration: 1000
                });
                scrollImg?.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setSuiteImgCount(1);
        };

            // function backAllImgsRoll(){

            //     wrapper?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
            //     });
            //     backWrapper2()
            //     wrapper41?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
            //     });
            //     wrapper42?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper43?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper44?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper45?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper46?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper47?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper48?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     setWrapper1Count(0);
            // }
            // function backWrapper2(){
            //     wrapper21?.scrollTo({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
            //     });
            //     wrapper22?.scrollTo({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
            //     });
                
                
            // }
            // function goImgRoll(){
            //     wrapper?.scrollBy({
            //         top: 0,
            //         left:400,
            //         behavior: 'smooth'
            //     });
            //     wrapper21?.scrollBy({
            //         top: 0,
            //         left:180,
            //         behavior: 'smooth'
            //     });
            //     wrapper22?.scrollBy({
            //         top: 0,
            //         left:180,
            //         behavior: 'smooth'
            //     });
            //     wrapper41?.scrollBy({
            //         top: 0,
            //         left:90,
            //         behavior: 'smooth'
            //     });
            //     wrapper42?.scrollBy({
            //         top: 0,
            //         left:90,
            //         behavior: 'smooth'
            //     });
            //     wrapper43?.scrollBy({
            //         top: 0,
            //         left:90,
            //         behavior: 'smooth'
            //     });
            //     wrapper44?.scrollBy({
            //         top: 0,
            //         left:90,
            //         behavior: 'smooth'
            //     });
            //     wrapper45?.scrollBy({
            //         top: 0,
            //         left:90,
            //         behavior: 'smooth'
            //     });
            //     wrapper46?.scrollBy({
            //         top: 0,
            //         left:90,
            //         behavior: 'smooth'
            //     });
            //     wrapper47?.scrollBy({
            //         top: 0,
            //         left:90,
            //         behavior: 'smooth'
            //     });
            //     wrapper48?.scrollBy({
            //         top: 0,
            //         left:90,
            //         behavior: 'smooth'
            //     });
                
            // }



            // function Back3ImgsRoll(){
            //     wrapper41?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
            //     });
            //     wrapper42?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper43?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper44?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper45?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper46?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper47?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper48?.scroll({
            //         top: 0,
            //         left:0,
            //         behavior: 'smooth'
                
            //     });
            //     wrapper?.scrollBy({
            //         top: 0,
            //         left:400,
            //         behavior: 'smooth'
            //     });
              
            // }


        useEffect(() => {
                    const arrowLeft = document.getElementById("arrowLeft");
                    const arrowRight = document.getElementById("arrowRight"); 
            if (suiteImgCount === 1) {
                arrowLeft?.setAttribute("style", "opacity:0");
                arrowRight?.setAttribute("style", "opacity:1");

            }
            if (suiteImgCount > 1) {
                arrowLeft?.setAttribute("style", "opacity:1")

            }
            if (suiteImgCount < suiteImgs.length) {
                arrowRight?.setAttribute("style", "opacity:1")
            }
            if (suiteImgCount === suiteImgs.length) {
                arrowRight?.setAttribute("style", "opacity:0")
            }
        }, [suiteImgCount, suiteImgs]);

        useEffect(() => {
            if (suiteSelectedCounter === 1) {
                SuiteNAction()
               
            };
            if (suiteSelectedCounter === 2) {
                SuiteCAction()
                
                
            };
            if (suiteSelectedCounter === 3) {
                SuiteSAction()

                
            };

            
        }, [suiteSelectedCounter]);
        useEffect(() => {
            const rightImg = document.getElementById("groupAboutNavR");
            const note = document.getElementById("note");
            const leftImg = document.getElementById("groupAboutNavL");
            const mainImg = document.getElementById("aboutUsFirst");
            if (selectedId === 0) {
                leftImg?.setAttribute("style", "opacity:0");
               
            }
            if (selectedId > 0) {
                leftImg?.setAttribute("style", "opacity:0.5");

            }
            if (selectedId > 1) {
                leftImg?.animate(
                    [{
                            opacity: "0.7"
                        },
                        {
                            opacity: "0"
                        }
                    ], {
                        duration: 1000
                    })
                leftImg?.animate(
                    [{
                            opacity: "0"
                        },
                        {
                            opacity: "0.5"
                        }
                    ], {
                        duration: 1000
                    })
            };
            if (selectedId < 5) {

                rightImg?.animate(
                    [{
                            opacity: "0.7"
                        },
                        {
                            opacity: "0"
                        }
                    ], {
                        duration: 1000
                    })
                rightImg?.animate(
                    [{
                            opacity: "0"
                        },
                        {
                            opacity: "0.5"
                        }
                    ], {
                        duration: 1000
                    });
            }
            if (selectedId < 5) {
                rightImg?.setAttribute("style", "opacity:0.5")

            };
            if (selectedId === 5) {
                rightImg?.setAttribute("style", "opacity:0");

            };
            mainImg?.animate(
                [{
                        opacity: "1"
                    },
                    {
                        opacity: "0"
                    }
                ], {
                    duration: 1000
                });
            mainImg?.animate(
                [{
                        opacity: "0.5"
                    },
                    {
                        opacity: "1"
                    }
                ], {
                    duration: 1000
                });
            note?.animate(
                [{
                        opacity: "0.1"
                    },
                    {
                        opacity: "1"
                    }
                ], {
                    duration: 2000
                });

        }, [selectedId]);

        // useEffect(()=>{



        //     if(wrapper1Count<=6){
                
        //         if(wrapper1Count===6){ 
        //             backAllImgsRoll();
                    
        //         }
        //         if(wrapper1Count===3){
        //             Back3ImgsRoll();
        //            backWrapper2();
                   
        //         }
        //         else{
        //             goImgRoll()

        //         }
               

            
        // }

        // },[wrapper1Count])
        // useEffect(() => {
        //     const interval = setInterval(() => {
        //       setWrapper1Count(Wrapper1Count => Wrapper1Count + 1);
        //     }, 4000);

        //     return () => {clearInterval(interval)
            
        //     };
            
        //   }, []);

    return(
   <>
       <TimeLapse/>
       <main id="mainHome">
       <section className="first-screen">
           

           <header>
               <ul> <li>
                   <Link to="/ComoChegar">
                   Como chegar
                   </Link></li>
                    <li className="ImgLi"><img src={logoDefault} alt="Logotipo Costa Florida Paradise"></img></li> 
                   <li> <Link to="/services">
                   Serviços
                   </Link></li>
               </ul>
           </header>
           <h2>O melhor de Floripa esta aqui, <br />Venha viver momentos <br />inesquecíveis.</h2>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} >
           <div onClick={()=>{
               handlePopUpDown();   
           }} 
            className="button-to-popup">
               <p>RESERVE-JÁ</p>
           </div>
           </motion.div>
           
       </section>
       
        <div id="mainContent">
                    {aboutData.map((data,index)=>{
                    if(index===selectedId){return(
                        <section key={data.id} className="aboutUs">
                        <img src={data.img} id="aboutUsFirst" alt={`${data.title} Foto`}></img>
        
                        <div className="action-menu">
                            <div id="cover" className="coverText">
                                <h3>Sobre nós</h3>
                                <p>Experimente nosso menu interativo, clique nas imagens</p>
                            </div>
        
        
                            <div className="navImgs">
                            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} >
                                <div id="groupAboutNavL">
                                    <div  onClick={()=>leftClick()}><img
                                            src={data.imgLast}
                                            id="aboutChangeImgLeft" 
                                            alt="Botão esquerdo para voltar a foto"></img></div>
                                    
                                </div>
                                </motion.div>
                                <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} >
                                <div id="groupAboutNavR">
                                    <div onClick={()=>rightClick()}><img
                                            src={data.imgNew}
                                            id="aboutChangeImgRight"
                                            alt="Botão esquerdo para avançar"
                                            ></img></div>
                                    
                                </div>
                                </motion.div>
                            </div>
        
        
                            <div id="note" className="noteAbout">
        
                                <h6>{data.title}</h6>
                                <p>{data.description}</p>
        
                                <p id="warn">Alguns serviços são cobrados a parte, verificar antecipadamente...</p>
                            </div>
        
        
                        </div>
                    </section>
                    )}})}

      




           <section className="suites">

               <div id="coverContent">
                   <div id="picWrapper">
                       <div id="pics">


                           {suiteImgs.map(img=>(
                           <div id="pic" key={img}>
                               <img id="scrollImg" src={img}
                               alt={"Quartos da pousada"}
                               ></img>
                                   </div>))}


                                   </div> 
                                   </div> 
                                   
                                <div className="suiteRightContent">
                               <h5>SUITE {suiteSelected.title}</h5>
                               <h6>Uma linda e cheirosa<br />{suiteSelected.description} <br /> com vista para um lindo
                                   nascer do sol na<br />Lagoa da Conceição.</h6>
                               <h6>Incluso:</h6>
                               <h6>
                                   *Produtos para higiene pessoal<br />
                                   *Secador de Cabelo<br />
                                   *Prancha de cabelo<br />
                                   *Ar-Condicionado<br />
                                   *Café da manhã<br />
                                   *TV Smart<br />
                                   *Wi-fi
                               </h6>
                               <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
                               <div 
                                onClick={()=>{
                                    handlePopUpDown();   
                                }} 
                               className="button-to-popup">
                                   <p>RESERVE-JÁ</p>
                               </div>
                               </motion.div>
                             
                           </div>
                       </div>



                       <div className="nav-bar">
                           <nav id="navTopContent">
                               <ul>
                               <li id="SuiteN" onClick={()=>{SuiteNAction() }}>SUITE <br />NORTE</li>
                               <li id="SuiteC" onClick={()=>{SuiteCAction()}}>SUITE <br />CENTRAL</li>
                               <li id="SuiteS" onClick={()=>{SuiteSAction()}}>SUITE <br />SUL</li>
                               </ul>
                           </nav>


                           <div id="navSuiteImg">
                           <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="arrowIcon" id="arrowLeft">
                               <IoIosArrowBack onClick={scrollDown} />   
                               </motion.div>
                               <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="arrowIcon" id="arrowRight" >
                               <IoIosArrowForward onClick={scrollUp}  />
                               </motion.div>
                           </div>
                       </div>

           </section>
           <section className="galeriaContent">
               <h3>GALERIA</h3>
               <p>Deslize as imagens para ver mais</p>
               <div className="gridsGaleria">

                   <div className="wrapper">
                       <div className="wrapperGalery" id="wrapper"> 
                           <div className="carrouselGalery"><img src={HospedeCosta11} alt={"Foto Hospedes"}></img></div>
                           <div className="carrouselGalery"><img src={SuiteRandom2} alt={"Foto Suites"}></img></div>
                           <div className="carrouselGalery"><img src={Praia7} alt={"Foto Praia"}></img></div>
                           <div className="carrouselGalery"><img src={HospedeCosta6} alt={"Foto Hospedes"}></img></div>
                           <div className="carrouselGalery"><img src={Barco2} alt={"Foto Barco"}></img></div>
                           <div className="carrouselGalery"><img src={HospedeCosta5} alt={"Foto Hospedes"}></img></div>
                       </div>
                   </div>


               
                       <div className="wrapper2">
                           <div className="wrapperGalery2" id="wrapper21">
                               <div className="carrouselGalery2"><img src={Praia6} alt={"Foto Praia"}></img></div>
                               <div className="carrouselGalery2"><img src={HospedeCosta8} alt={"Foto Hospedes"}></img></div>
                               <div className="carrouselGalery2"><img src={Prai2} alt={"Foto Praia"}></img></div>
                               <div className="carrouselGalery2"><img src={HospedeCosta2} alt={"Foto Hospedes"}></img></div>
                               <div className="carrouselGalery2"><img src={Balanco4} alt={"Foto Balanço da praia"}></img></div>
                               <div className="carrouselGalery"><img  src={HospedeCosta3} alt={"Foto Hospedes"}></img></div>
                           </div>
                       </div>
                       <div className="wrapper2">
                           <div className="wrapperGalery2" id="wrapper22">
                               <div className="carrouselGalery2"><img src={HospedeCosta4} alt={"Foto Hospedes"}></img></div>
                               <div className="carrouselGalery2"><img src={Praia6} alt={"Foto Praia"}></img></div>
                               <div className="carrouselGalery2"><img src={HospedeCosta10} alt={"Foto Hospedes"}></img></div>
                               <div className="carrouselGalery2"><img src={Balanco2} alt={"Foto Balanço da praia"}></img></div>
                               <div className="carrouselGalery2"><img src={HospedeCosta9} alt={"Foto Hospedes"}></img></div>
                               <div className="carrouselGalery"><img src={Random5} alt={"Entrada da pousada"}></img></div>
                           </div>
                       </div>

                
                  
                      


                           <div className="wrapper4" style={{"gridArea": "wrapperL41"}}>
                               <div className="wrapperGalery4" id="wrapper41">
                                   <div className="carrouselGalery4"><img src={Balanco5} alt={"Foto Balanço da praia"}></img></div>
                                   <div className="carrouselGalery4"><img src={Barco} alt={"Foto Barco"}></img></div>
                                   <div className="carrouselGalery4"><img src={HospedeCosta} alt={"Foto Hospedes"}></img></div>
                               </div>
                           </div>

                           <div className="wrapper4" style={{"gridArea": "wrapperL42"}}>
                               <div className="wrapperGalery4" id="wrapper42">
                                <div className="carrouselGalery4"><img src={Random} alt={"Foto Suites"}></img></div>
                                   <div className="carrouselGalery4"><img src={SuiteRandom}  alt={"Foto Suites"}></img></div>
                                   <div className="carrouselGalery4"><img src={Praia3} alt={"Foto Praia"}></img></div>
                               </div>
                           </div>

                           <div className="wrapper4"style={{"gridArea": "wrapperL43"}}>
                               <div className="wrapperGalery4" id="wrapper43">
                                   <div className="carrouselGalery4"><img src={Praia} alt={"Foto Praia"}></img></div>
                                   <div className="carrouselGalery4"><img src={HospedeCosta7} alt={"Foto Hospedes"}></img></div>
                                   <div className="carrouselGalery4"><img src={Random2} alt={"Vista para praia"}></img></div>
                               </div>
                           </div>

                           <div className="wrapper4" style={{"gridArea": "wrapperL44"}}>
                               <div className="wrapperGalery4" id="wrapper44">
                                <div className="carrouselGalery4"><img src={Balanco} alt={"Foto Balanço da praia"}></img></div>
                                   <div className="carrouselGalery4"><img src={Praia4} alt={"Foto Praia"}></img></div>
                                   <div className="carrouselGalery4"><img src={SuiteRandom3}  alt={"Foto Suites"}></img></div>
                               </div>
                           </div>
                           

            
                       
                           <div className="wrapper4"style={{"gridArea": "wrapperR41"}}>
                               <div className="wrapperGalery4" id="wrapper45">
                                <div className="carrouselGalery4"><img src={Barco3} alt={"Foto Barco"}></img></div>
                                   <div className="carrouselGalery4"><img src={Random3} alt={"Brco e balanço"}></img></div>
                                   <div className="carrouselGalery4"><img src={Random4} alt={"Fim do dia Foto"}></img></div>
                               </div>
                           </div>
                           <div className="wrapper4" style={{"gridArea": "wrapperR42"}}>
                               <div className="wrapperGalery4" id="wrapper46">
                               <div className="carrouselGalery4"><img src={Balanco3} alt={"Foto Balanço da praia"}></img></div>
                                   <div className="carrouselGalery4"><img src={Random6} alt={"Decoração Romântica"}></img></div>
                                   <div className="carrouselGalery4"><img src={Random7} alt={"Foto Balanço da praia"}></img></div>
                               </div>
                           </div>
                           <div className="wrapper4"style={{"gridArea": "wrapperR43"}}>
                               <div className="wrapperGalery4" id="wrapper47">
                                w<div className="carrouselGalery4"><img src={Random8} alt={"Foto vista para praia"}></img></div>
                                   <div className="carrouselGalery4"><img src={Random9} alt={"Foto do barco"}></img></div>
                                   <div className="carrouselGalery4"><img src={Random10} alt={"Salada Jantar"}></img></div>
                               </div>
                           </div>
                           <div className="wrapper4"style={{"gridArea": "wrapperR44"}}>
                               <div className="wrapperGalery4" id="wrapper48">
                                 <div className="carrouselGalery4"><img src={Random11}alt={"Foto vista para praia"}></img></div>
                                   <div className="carrouselGalery4"><img src={HospedeCosta11} alt={"Foto Hospedes"}></img></div>
                                   <div className="carrouselGalery4"><img src={Praia7} alt={"Foto Praia"}></img></div>
                               </div>
                           </div>
                           
                       
            
               </div>
                           <p style={{"textShadow":"none"}}>Tanta gente feliz, vem ser feliz também...</p>

           </section>
           </div>
       </main>
       <Footer/>
   </>
        
)
}
 export default Home;