import React from "react"
import {IoIosMail,IoLogoWhatsapp,IoLogoFacebook,IoLogoInstagram,IoIosHome,IoIosLocate} from "react-icons/io";
import "./styles.css";
import {Link} from "react-router-dom";

const Footer =()=>{
    return(
        <footer>
            <div className="footerSection">
                <h6>Contato comercial</h6>
                <div className="footerContent">

                    <div className="centeredLine"  >
                        <IoIosMail style={{"color":"#2D3A45","fontSize":51}} />
                        <p>costaflorida@hotmail.com</p>
                    </div>
                    <a className="centeredLine" target="blank" rel="noopener noreferrer" href="https://wa.me/5547991511226">
                        <IoLogoWhatsapp style={{"color":"#34af23","fontSize":51}} />
                        <p>+55 47 991511226</p>
                    </a>

                </div>
            </div>

            <div className="footerSection">
                <h6>Redes sociais</h6>
                <div className="footerContent">
                    <a className="centeredLine" rel="noopener noreferrer" href="https://www.facebook.com/Pousada-Costa-Florida-349947545858712/"
                        target="_blank">
                        <IoLogoFacebook style={{"color":"#3b5998","fontSize":51}} />
                        <p>Pousada Costa Florida</p>
                    </a>
                    <a className="centeredLine" rel="noopener noreferrer" href="https://www.instagram.com/costafloridaparadise/" target="_blank">
                        <IoLogoInstagram style={{"color":"#3f729b","fontSize":51}} />
                        <p>@pousadacostaflorida</p>
                    </a>

                </div>

            </div>

            <div className="footerSection">
                <h6>Endereço</h6>
                <div className="footerContent">
                    <a className="centeredLine" target="blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Cooperbarco+Trapiche+P.3+Canto+Dos+Ara%C3%A7%C3%A1s/@-27.5852225,-48.4653418,14.25z/data=!4m13!1m7!3m6!1s0x95273f5ffa171a0f:0x7989e2b1f12039c!2sServid%C3%A3o+Caminho+Costa+da+Lagoa,+Florian%C3%B3polis+-+SC!3b1!8m2!3d-27.5532228!4d-48.4630497!3m4!1s0x0:0x2de376376ac3ba37!8m2!3d-27.5859364!4d-48.4607631">
                        <IoIosHome style={{"color":"#2D3A45","fontSize":51}} />
                        <p>Servidão Caminho Costa da Lagoa</p>
                    </a>
                    <a className="centeredLine" target="blank" rel="noopener noreferrer" href="https://www.google.com/maps?q=ponto+13+costa+da+lagoa&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjP6JSwrZjrAhX_G7kGHfM5BgUQ_AUoAXoECBkQAw">
                        <IoIosLocate style={{"color":"#2D3A45","fontSize":51}} />
                        <p>Ponto 13</p>
                    </a>
                    <p style={{"color":"#2D3A45","marginTop":"16px"}}>Para mais informações sobre endereço <br />visite nossa aba <Link
                            to="/ComoChegar"><b style={{"color":"#2D3A45"}}>como chegar.</b></Link>
                    </p>
                </div>
            </div>
        </footer>
    )
    }
    export default Footer;